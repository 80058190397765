const Youtube = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    role="img"
    aria-label="[title + description]"
  >
    <title>Youtube Icon</title>
    <desc>Headout&apos;s Youtube Handle</desc>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.46449 2.18115C5.57049 1.9557 6.76093 1.83333 8 1.83333C9.23907 1.83333 10.4295 1.9557 11.5355 2.18115L11.6203 2.19844C12.8834 2.45552 13.725 2.62682 14.4503 3.55928C15.1677 4.48171 15.1673 5.55146 15.1667 7.20826V8.79173C15.1673 10.4485 15.1677 11.5183 14.4503 12.4407C13.725 13.3732 12.8834 13.5445 11.6203 13.8015L11.5355 13.8189C10.4295 14.0443 9.23907 14.1667 8 14.1667C6.76093 14.1667 5.57049 14.0443 4.46449 13.8189L4.37964 13.8015C3.11655 13.5445 2.27495 13.3732 1.54972 12.4407C0.832268 11.5183 0.832668 10.4485 0.833288 8.79173V7.20826C0.832668 5.55146 0.832268 4.48171 1.54972 3.55928C2.27495 2.62682 3.11655 2.45552 4.37964 2.19844L4.46449 2.18115ZM7.52967 5.50149C7.87534 5.64657 8.29527 5.88108 8.80867 6.16782C8.87627 6.20555 8.9442 6.24305 9.0122 6.28058C9.3478 6.46585 9.68534 6.65219 9.9966 6.876C10.2763 7.07713 10.5417 7.3264 10.6296 7.68993C10.6789 7.89399 10.6789 8.106 10.6296 8.31006C10.5417 8.6736 10.2763 8.92286 9.9966 9.12399C9.68534 9.3478 9.34773 9.53419 9.01213 9.71946C8.94413 9.757 8.87627 9.79446 8.80867 9.83219C8.29527 10.1189 7.8754 10.3534 7.52967 10.4985C6.96767 10.7343 6.37173 10.7535 5.88192 10.3521C5.57536 10.1009 5.467 9.74126 5.41945 9.37886C5.30455 8.50293 5.30449 7.49746 5.41945 6.62114C5.467 6.2587 5.57536 5.89905 5.88192 5.64787C6.37173 5.24655 6.96767 5.26563 7.52967 5.50149Z"
      fill="currentColor"
    />
  </svg>
);

export default Youtube;
