const MessageIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.06591 12.6667C3.19917 12.5814 2.54987 12.3211 2.11442 11.8856C1.33337 11.1046 1.33337 9.84749 1.33337 7.33335V7.00002C1.33337 4.48586 1.33337 3.22879 2.11442 2.44773C2.89547 1.66669 4.15255 1.66669 6.66671 1.66669H9.33337C11.8475 1.66669 13.1046 1.66669 13.8856 2.44773C14.6667 3.22879 14.6667 4.48586 14.6667 7.00002V7.33335C14.6667 9.84749 14.6667 11.1046 13.8856 11.8856C13.1046 12.6667 11.8475 12.6667 9.33337 12.6667C8.95971 12.675 8.66211 12.7034 8.36977 12.77C7.57084 12.954 6.83104 13.3628 6.09995 13.7193C5.05823 14.2272 4.53737 14.4812 4.2105 14.2434C3.58517 13.7777 4.1964 12.3346 4.33337 11.6667"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);

export default MessageIcon;
