const Twitter = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    role="img"
    aria-label="[title + description]"
  >
    <title>Twitter Icon</title>
    <desc>Headout&apos;s Twitter Handle</desc>
    <path
      d="M1.57127 1.85409C1.68239 1.63675 1.90591 1.5 2.15 1.5H5.4C5.60872 1.5 5.80474 1.60023 5.92694 1.76943L9.0236 6.05714L13.3904 1.69038C13.6442 1.43654 14.0558 1.43654 14.3096 1.69038C14.5635 1.94422 14.5635 2.35578 14.3096 2.60962L9.7946 7.12467L14.3769 13.4694C14.5199 13.6673 14.5399 13.9286 14.4287 14.1459C14.3176 14.3633 14.0941 14.5 13.85 14.5H10.6C10.3913 14.5 10.1953 14.3998 10.0731 14.2306L6.9764 9.94287L2.60962 14.3096C2.35578 14.5635 1.94423 14.5635 1.69038 14.3096C1.43654 14.0558 1.43654 13.6442 1.69038 13.3904L6.20541 8.87533L1.62306 2.53057C1.48015 2.33269 1.46014 2.07142 1.57127 1.85409Z"
      fill="currentColor"
    />
  </svg>
);
export default Twitter;
