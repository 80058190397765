const MailIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33337 4L5.94205 6.61131C7.64111 7.574 8.35897 7.574 10.058 6.61131L14.6667 4"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M1.34389 8.98371C1.38747 11.0274 1.40926 12.0492 2.16335 12.8062C2.91743 13.5632 3.96693 13.5895 6.06593 13.6422C7.35957 13.6748 8.64051 13.6748 9.93417 13.6422C12.0332 13.5895 13.0826 13.5632 13.8368 12.8062C14.5908 12.0492 14.6126 11.0274 14.6562 8.98371C14.6702 8.32658 14.6702 7.67338 14.6562 7.01625C14.6126 4.97255 14.5908 3.95071 13.8368 3.19375C13.0826 2.4368 12.0332 2.41043 9.93417 2.35769C8.64051 2.32519 7.35957 2.32519 6.06592 2.35769C3.96693 2.41042 2.91743 2.43679 2.16334 3.19375C1.40925 3.9507 1.38747 4.97255 1.34388 7.01625C1.32987 7.67338 1.32987 8.32658 1.34389 8.98371Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
);

export default MailIcon;
