const Facebook = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    role="img"
    aria-label="[title + description]"
  >
    <title>Facebook Icon</title>
    <desc>Headout&apos;s Facebook Handle</desc>
    <path
      d="M10.6144 4.501C10.0307 4.50094 9.51163 4.5009 9.09256 4.55726C8.63709 4.61852 8.17716 4.75978 7.80123 5.1358C7.42529 5.51182 7.28409 5.9719 7.22289 6.42754C7.16656 6.84674 7.16656 7.366 7.16663 7.94987V8.50227H6.66663C6.20639 8.50227 5.83329 8.87547 5.83329 9.33587C5.83329 9.7962 6.20639 10.1695 6.66663 10.1695H7.16663V14.1677C7.16663 14.4839 7.16663 14.642 7.06749 14.7399C6.96836 14.8379 6.81149 14.8359 6.49776 14.8319C5.7048 14.8219 5.03046 14.7941 4.45864 14.7171C3.54087 14.5937 2.8127 14.3356 2.24057 13.7633C1.66844 13.191 1.41041 12.4626 1.28702 11.5445C1.16661 10.6486 1.16662 9.50114 1.16663 8.0402V7.96394C1.16662 6.50304 1.16661 5.35555 1.28702 4.45966C1.41041 3.5416 1.66844 2.8132 2.24057 2.24089C2.8127 1.66858 3.54087 1.41047 4.45864 1.28704C5.35425 1.1666 6.50139 1.1666 7.96183 1.16661H8.03809C9.49856 1.1666 10.6457 1.1666 11.5413 1.28704C12.459 1.41047 13.1872 1.66858 13.7594 2.24089C14.3315 2.8132 14.5895 3.5416 14.7129 4.45966C14.8333 5.35555 14.8333 6.50305 14.8333 7.96394V8.04027C14.8333 9.50114 14.8333 10.6486 14.7129 11.5445C14.5895 12.4626 14.3315 13.191 13.7594 13.7633C13.1872 14.3356 12.459 14.5937 11.5413 14.7171C10.9695 14.7941 10.2951 14.8219 9.50216 14.8319C9.18843 14.8359 9.03156 14.8379 8.93243 14.7399C8.83329 14.642 8.83329 14.4839 8.83329 14.1677V10.1695H9.99996C10.4602 10.1695 10.8333 9.7962 10.8333 9.33587C10.8333 8.87547 10.4602 8.50227 9.99996 8.50227H8.83329V8.00207C8.83329 7.3498 8.83509 6.9444 8.87469 6.64968C8.91043 6.38366 8.96323 6.33111 8.97896 6.31548L8.97976 6.31468L8.98056 6.31388C8.99616 6.29817 9.04869 6.24535 9.31463 6.20958C9.60929 6.16996 10.0146 6.16819 10.6666 6.16819H11.3333C11.7936 6.16819 12.1666 5.79498 12.1666 5.33459C12.1666 4.87421 11.7936 4.501 11.3333 4.501H10.6144Z"
      fill="currentColor"
    />
  </svg>
);
export default Facebook;
