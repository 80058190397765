const PhoneIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.51845 7.96161C1.88644 6.85954 1.58127 5.95966 1.39727 5.04748C1.12512 3.6984 1.74791 2.38055 2.77963 1.53966C3.21567 1.18426 3.71553 1.30569 3.97337 1.76828L4.55549 2.81262C5.0169 3.64039 5.2476 4.05427 5.20184 4.49307C5.15609 4.93187 4.84495 5.28925 4.22269 6.00402L2.51845 7.96161ZM2.51845 7.96161C3.79771 10.1922 5.80527 12.2009 8.03844 13.4816M8.03844 13.4816C9.14051 14.1136 10.0404 14.4188 10.9526 14.6028C12.3016 14.8749 13.6195 14.2521 14.4604 13.2204C14.8158 12.7844 14.6944 12.2845 14.2318 12.0267L13.1874 11.4445C12.3596 10.9831 11.9458 10.7525 11.507 10.7982C11.0682 10.8439 10.7108 11.1551 9.99604 11.7773L8.03844 13.4816Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
);

export default PhoneIcon;
