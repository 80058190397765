const Linkedin = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    role="img"
    aria-label="[title + description]"
  >
    <title>LinkedIn Icon</title>
    <desc>Headout&apos;s LinkedIn Handle</desc>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.96183 1.16667H8.03809C9.49856 1.16667 10.6457 1.16666 11.5413 1.28707C12.459 1.41046 13.1872 1.66848 13.7594 2.24061C14.3315 2.81275 14.5895 3.54092 14.7129 4.45869C14.8333 5.3543 14.8333 6.50143 14.8333 7.96187V8.03814C14.8333 9.49861 14.8333 10.6457 14.7129 11.5413C14.5895 12.4591 14.3315 13.1873 13.7594 13.7594C13.1872 14.3315 12.459 14.5895 11.5413 14.7129C10.6457 14.8333 9.49856 14.8333 8.03809 14.8333H7.96183C6.50136 14.8333 5.35425 14.8333 4.45864 14.7129C3.54087 14.5895 2.8127 14.3315 2.24057 13.7594C1.66844 13.1873 1.41041 12.4591 1.28702 11.5413C1.16661 10.6457 1.16662 9.49861 1.16663 8.03814V7.96187C1.16662 6.50141 1.16661 5.3543 1.28702 4.45869C1.41041 3.54092 1.66844 2.81275 2.24057 2.24061C2.8127 1.66848 3.54087 1.41046 4.45864 1.28707C5.35425 1.16666 6.50139 1.16667 7.96183 1.16667ZM5.33459 7.00001C5.33459 6.63181 5.03612 6.33334 4.66793 6.33334C4.29974 6.33334 4.00126 6.63181 4.00126 7.00001V11.3333C4.00126 11.7015 4.29974 12 4.66793 12C5.03612 12 5.33459 11.7015 5.33459 11.3333V7.00001ZM7.33463 6.00001C7.60483 6.00001 7.83756 6.16081 7.94223 6.39194C8.34749 6.14334 8.82429 6.00001 9.33463 6.00001C10.8074 6.00001 12.0013 7.19394 12.0013 8.66667V11.3333C12.0013 11.7015 11.7028 12 11.3346 12C10.9664 12 10.668 11.7015 10.668 11.3333V8.66667C10.668 7.93027 10.071 7.33334 9.33463 7.33334C8.59823 7.33334 8.00129 7.93027 8.00129 8.66667V11.3333C8.00129 11.7015 7.70276 12 7.33463 12C6.96643 12 6.66796 11.7015 6.66796 11.3333V6.66667C6.66796 6.29848 6.96643 6.00001 7.33463 6.00001ZM5.50647 4.66667C5.50647 5.12691 5.13337 5.50001 4.67314 5.50001H4.66715C4.20691 5.50001 3.83381 5.12691 3.83381 4.66667C3.83381 4.20643 4.20691 3.83334 4.66715 3.83334H4.67314C5.13337 3.83334 5.50647 4.20643 5.50647 4.66667Z"
      fill="currentColor"
    />
  </svg>
);

export default Linkedin;
